API.Navbar = {};
CFG.Navbar = {};

CFG.Navbar.ToggleOffset = 64;

// Handles window scroll event and resizes navbar
API.Navbar.OnWindowScroll = function() {
  var current = $(window).scrollTop();
  var navbar_instance = $("#navbar");
  if (current > CFG.Navbar.ToggleOffset) {
    navbar_instance.addClass("navbar-small");
  } else {
    navbar_instance.removeClass("navbar-small");
  }
};

API.Navbar.ToggleMenu = function() {
  $("#navbar").toggleClass('navbar-mobile');
  if ($("#navbar").hasClass('navbar-mobile')) {
    $("#navbar .nav-mobile").removeClass('hide');
    $("div.navbar-close").css("display", "block");
  } else {
    $("#navbar .nav-mobile").addClass('hide');
    $("div.navbar-close").css("display", "none");
  }
};

API.Navbar.OnResize = function() {
  if ($(window).width() > CFG.Layout.MediumScreen) {
    $("#navbar").removeClass('navbar-mobile');
    $("div.navbar-close").remove();
  } else {
    if ($("div.navbar-close").length == 0) {
      $("body").append('<div class="navbar-close"></div>');
      $("div.navbar-close").css("display", "none");
      $("div.navbar-close").click(API.Navbar.ToggleMenu);
    }
  }
};

// When document loaded
$(function() {
  API.Navbar.OnWindowScroll();
  API.Navbar.OnResize();

  $("#navbar .button-collapse").click(API.Navbar.ToggleMenu);

  $(window).scroll(API.Navbar.OnWindowScroll);
  $(window).resize(API.Navbar.OnResize);
});
